import { render, staticRenderFns } from "./header-desktop.vue?vue&type=template&id=76ced0bd"
import script from "./header-desktop.vue?vue&type=script&lang=js"
export * from "./header-desktop.vue?vue&type=script&lang=js"
import style0 from "./header-desktop.vue?vue&type=style&index=0&id=76ced0bd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMainLogo: require('/app/components/icons/IconMainLogo.vue').default,VDivider: require('/app/node_modules/vuetify/lib/components/VDivider/VDivider.js').default,IconLocation: require('/app/components/icons/IconLocation.vue').default,VBtn: require('/app/node_modules/vuetify/lib/components/VBtn/VBtn.js').default,UserPanel: require('/app/components/dashboard/user-panel.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VDivider,VExpandTransition})
